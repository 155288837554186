@-ms-viewport {
  width: device-width; }

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs, .banner .content .sub_title br {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.33333333%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.33333333%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0%; } }

@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.33333333%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.33333333%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0%; } }

@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.33333333%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.33333333%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0%; } }

.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

*,
*:before,
*:after {
  box-sizing: border-box; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent; }

td,
th {
  padding: 0; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #dddddd; }

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 2px solid #dddddd; }

.table .table {
  background-color: #ffffff; }

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #fff; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dddddd; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive > .table-bordered {
    border: 0; }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

img {
  border: 0;
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination > li {
  display: inline; }

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px; }

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; }

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #dddddd; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #ffffff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default; }

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #ffffff;
  border-color: #dddddd;
  cursor: not-allowed; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/graphik/Graphik-Regular.eot);
  src: url(/fonts/graphik/Graphik-Regular.eot?#iefix) format("embedded-opentype"), url(/fonts/graphik/Graphik-Regular.woff) format("woff"), url(/fonts/graphik/Graphik-Regular.ttf) format("truetype"), url(/fonts/graphik/Graphik-Regular.svg#Graphik-Regular) format("svg");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/graphik/Graphik-Light.eot);
  src: url(/fonts/graphik/Graphik-Light.eot?#iefix) format("embedded-opentype"), url(/fonts/graphik/Graphik-Light.woff) format("woff"), Modern Browsers url(/fonts/graphik/Graphik-Light.ttf) format("truetype"), url(/fonts/graphik/Graphik-Light.svg#Graphik-Light) format("svg");
  text-rendering: optimizeLegibility; }

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/graphik/Graphik-Medium.eot);
  src: url(/fonts/graphik/Graphik-Medium.eot?#iefix) format("embedded-opentype"), url(/fonts/graphik/Graphik-Medium.woff) format("woff"), url(/fonts/graphik/Graphik-Medium.ttf) format("truetype"), url(/fonts/graphik/Graphik-Medium.svg#Graphik-Medium) format("svg");
  text-rendering: optimizeLegibility; }

* {
  box-sizing: border-box; }

body {
  font-family: Graphik, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased; }

table tr td, table tr th {
  page-break-inside: avoid; }

p {
  margin: 0; }
  p + p {
    margin-top: 1em; }

img {
  max-width: 100%;
  height: auto; }

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: baseline;
  box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    #canvas {
      display: none; } }

.receipt_container {
  text-align: left;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #fafafa;
  border-radius: 4px; }
  .receipt_container p {
    line-height: 1.5em; }
  .receipt_container h5 {
    margin-bottom: 5px; }
  .receipt_container .total_amount span {
    display: inline-block;
    margin-top: 5px;
    font-weight: bold;
    font-size: 2em; }
  .receipt_container a.blue-bordered {
    display: inline-block;
    margin-top: 10px; }
  .receipt_container .highlight_text {
    color: green; }

.cf:after,
.cf:before {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

@media only screen and (max-width: 768px) {
  .cf-mobile {
    float: left !important;
    clear: both !important;
    margin-bottom: 4px; } }

@media only screen and (max-width: 480px) {
  .big-xs {
    font-size: 2em; } }

.hidden, .hide {
  display: none !important; }

.text-white {
  color: #fff; }

.text-bold {
  font-weight: 900; }

.text-muted {
  color: #777; }

.blue {
  color: #1894dd; }
  .blue:hover {
    color: #1685c6; }

.blue-bordered {
  color: #1894dd;
  text-decoration: none;
  padding: 4px;
  border: 1px solid #1894dd;
  border-radius: 4px;
  transition: all 0.4s ease-in-out; }
  .blue-bordered:hover {
    color: #0a385c;
    border: 1px solid #0a385c; }

.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.text-justify {
  text-align: justify !important; }

.circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100px; }

.error {
  color: #d14130;
  padding-top: 4px;
  display: block; }
  .error.error-msg {
    display: none; }
  .error.error--center {
    text-align: center; }

.white {
  color: #fff !important; }

.border {
  display: block;
  height: 2px;
  width: 100%;
  background-color: #1894dd; }

.border-repeated {
  height: 6px;
  border: 0;
  background-color: #a086c4;
  background-image: linear-gradient(to right, #a086c4 0%, #a086c4 25%, #ff9700 25%, #ff9700 50%, #f75655 50%, #f75655 75%, #00ace6 75%, #00ace6 100%, #00ace6 100%);
  background-size: 50%; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.mb1 {
  margin-bottom: 1em !important; }

.mb2 {
  margin-bottom: 2em !important; }

.mt3 {
  margin-top: 3em !important; }

.mt5 {
  margin-top: 5em !important; }

.mb3 {
  margin-bottom: 3em !important; }

.mb5 {
  margin-bottom: 5em !important; }

.top_absolute {
  font-size: 0.8em;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  padding: 15px 20px;
  color: #666;
  background-color: #f2f2f2;
  text-align: center; }
  .top_absolute .close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2; }
  .top_absolute img {
    display: inline-block;
    vertical-align: middle; }
  .top_absolute a {
    text-decoration: underline; }
    .top_absolute a:hover {
      text-decoration: none; }

.inline {
  color: #3f3d4e;
  overflow: auto;
  background: #FDFDF6;
  padding: 20px;
  width: 600px;
  max-width: 100%;
  border-radius: 6px; }
  .inline a {
    color: #0c4673; }
    .inline a:hover {
      color: #3f3d4e; }
  .inline h1 {
    font-size: 1.4em;
    text-align: left;
    color: #3f3d4e;
    text-shadow: none; }
  .inline h2 {
    color: #3f3d4e; }

.intl-tel-input {
  width: 100%; }

#sk-container {
  font-family: Graphik !important; }

.video_container > div:not(.plyr--fullscreen-active) {
  border: 3px solid #1894dd; }

.sweet-alert {
  font-family: Graphik, sans-serif; }

.wrapper {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    .wrapper {
      max-width: 90%; } }
  .wrapper--no-padding .col-sm-6 {
    padding-left: 0;
    padding-right: 0; }

.text_wrap {
  width: 100%;
  max-width: 670px;
  padding: 0 2em;
  margin: 0px auto; }

.medium_wrapper {
  width: 960px;
  max-width: 100%;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    .medium_wrapper {
      max-width: 70%; } }

.header {
  position: relative;
  z-index: 20;
  padding: 2rem 0; }
  .header__logo {
    display: block;
    color: #fff;
    font-weight: 700;
    font-size: 1.35rem;
    float: left;
    margin: 0; }
  .header__menu-toggler {
    display: none;
    float: left; }
    @media only screen and (max-width: 768px) {
      .header__menu-toggler {
        display: block;
        color: #fff;
        font-size: 30px;
        margin: 0 20px;
        cursor: pointer; }
        .header__menu-toggler:hover {
          opacity: 0.8; } }
  .header__menu {
    float: right;
    transition: left 0.3s ease; }
    @media only screen and (max-width: 768px) {
      .header__menu {
        position: fixed;
        float: none;
        display: block;
        background: #fff;
        width: 240px;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        z-index: 9999; } }
    .header__menu.closed {
      left: -240px; }
    .header__menu__close-btn {
      position: relative;
      height: 23px;
      width: 23px;
      background: #fff;
      cursor: pointer;
      float: right;
      margin: 20px 20px 0 0; }
      .header__menu__close-btn:after, .header__menu__close-btn:before {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 0;
        left: 50%;
        transform: rotate(45deg);
        height: 23px;
        width: 2px;
        background: #1894dd;
        content: '';
        display: block;
        margin: -3px 0 0 -1px; }
      .header__menu__close-btn:hover:after, .header__menu__close-btn:hover:before {
        background: #1375af; }
      .header__menu__close-btn:before {
        transform: rotate(-45deg); }
      @media only screen and (min-width: 768px) {
        .header__menu__close-btn {
          display: none; } }
    .header__menu ul {
      clear: both;
      margin: 0;
      padding: 8px 0 0; }
      .header__menu ul li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: inline;
        margin-right: 0; }
        .header__menu ul li.more {
          position: relative; }
          .header__menu ul li.more ul {
            display: none; }
          .header__menu ul li.more.active ul {
            display: block; }
          .header__menu ul li.more > a > i {
            color: #d2d2d2; }
          @media only screen and (max-width: 768px) {
            .header__menu ul li.more ul a {
              padding-left: 40px; } }
          @media only screen and (min-width: 768px) {
            .header__menu ul li.more ul {
              display: none;
              top: 30px;
              left: 30px;
              margin: 0;
              padding: 0;
              z-index: 9000;
              position: absolute;
              background: #fff;
              border-radius: 5px;
              box-shadow: 0 8px 13px rgba(0, 0, 0, 0.36), 0 0 0 1px rgba(0, 0, 0, 0.06);
              width: 212px;
              box-sizing: border-box;
              transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
              transform-origin: 39px -10px;
              transform: scale(1);
              opacity: 1;
              filter: none; }
              .header__menu ul li.more ul:before {
                content: "";
                position: absolute;
                display: block;
                width: 37px;
                height: 12px;
                top: -12px;
                left: 20px;
                background-image: url("../../images/arrow_top.png"); }
              .header__menu ul li.more ul li {
                display: block;
                margin: 0; }
                .header__menu ul li.more ul li.separator:before {
                  content: "";
                  display: block;
                  height: 1px;
                  background: rgba(0, 0, 0, 0.05);
                  margin: 7.5px 15px; }
                .header__menu ul li.more ul li:first-child a {
                  padding-top: 15px; }
                .header__menu ul li.more ul li a {
                  display: block;
                  margin: 0;
                  line-height: 1.45rem;
                  padding: 5px 15px;
                  -webkit-font-smoothing: subpixel-antialiased;
                  color: #6f7c82; }
                  .header__menu ul li.more ul li a:hover {
                    color: #323334; } }
        .header__menu ul li a {
          color: #f2f1f8;
          text-decoration: none;
          margin-left: 2rem;
          cursor: pointer; }
          .header__menu ul li a:hover {
            color: #fff; }
          @media only screen and (max-width: 768px) {
            .header__menu ul li a {
              display: block;
              margin: 0;
              color: #617076;
              padding: 10px 20px; }
              .header__menu ul li a + .header__menu ul li a {
                margin-top: 10px; }
              .header__menu ul li a:hover {
                background: #eee;
                color: #617076; }
              .header__menu ul li a.login_link, .header__menu ul li a.blue_on_mobile {
                background: #1894dd;
                color: #fff;
                margin: 10px 0 0 0;
                text-align: left;
                border-radius: 0;
                padding-top: 15px;
                padding-bottom: 15px; }
                .header__menu ul li a.login_link:hover, .header__menu ul li a.blue_on_mobile:hover {
                  background: #116698; } }
          .header__menu ul li a i {
            font-size: 0.7rem; }

.form-group:last-child, .input-group:last-child {
  margin-bottom: 0; }

.form-group button {
  width: 100%;
  outline: 0; }

.form-group input[type=email]:focus, .form-group input[type=password]:focus, .form-group input[type=text]:focus, .form-group textarea:focus, .input-group button:focus, .input-group input[type=email]:focus, .input-group input[type=password]:focus, .input-group input[type=text]:focus, .input-group textarea:focus {
  box-shadow: 0 0 2px #1894dd; }

#main-footer {
  background: #f2f2f2 none repeat scroll 0 0;
  color: #8e8e8e;
  letter-spacing: -0.2px;
  min-height: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 4px solid #e5e9ee; }
  #main-footer a {
    color: #8e8e8e;
    text-decoration: none; }
  #main-footer .logo {
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #464646;
    display: inline-block;
    margin-bottom: 15px; }
  #main-footer h4, #main-footer h5 {
    margin-top: 0;
    line-height: 1.4em; }
  #main-footer h4 {
    font-size: 18px; }
  #main-footer h5 {
    color: #343434;
    font-size: 1.4em;
    margin-bottom: 25px; }
  #main-footer .footer__site-description {
    padding-right: 50px;
    line-height: 1.5em; }
  #main-footer ul {
    list-style: outside none none;
    margin: 0;
    padding: 0; }
    #main-footer ul li {
      line-height: 1.9em; }
      #main-footer ul li a:hover {
        color: #323334; }
  #main-footer .footer__connect {
    margin-top: 40px;
    margin-bottom: 2em;
    padding: 0; }
  #main-footer .footer__connect li {
    display: inline-block;
    font-size: 18px;
    margin-right: 8px;
    text-align: center;
    width: 40px; }
  #main-footer .footer__connect li a {
    border-radius: 4px;
    display: block;
    padding: 0.2em 0;
    font-size: 14px;
    color: #fff; }
  #main-footer .footer__connect li a:hover {
    background-color: #0c4673;
    color: #fff; }
  #main-footer .footer__connect a[href*="facebook"] {
    background-color: #3b5a92; }
  #main-footer .footer__connect a[href*="twitter"] {
    background-color: #59adec; }
  #main-footer .footer__connect a[href*="plus.google"] {
    background-color: #df4b38; }
  #main-footer .footer__connect a[href*="instagram"] {
    background-color: #323232; }
  #main-footer .footer__connect a[href*="youtube"] {
    background-color: #cc181e; }
  #main-footer .footer__connect a[href*="linkedin"] {
    background-color: #0077b5; }
  #main-footer .footer__connect a[href*="github"] {
    background-color: #777; }
  #main-footer ol, #main-footer ul {
    margin-bottom: 10px;
    margin-top: 0; }
  #main-footer .footer__bottom {
    text-align: center;
    font-size: 0.9rem;
    padding-top: 40px; }
  #main-footer .footer__bottom a {
    font-weight: 700; }

#services {
  background: #f2f1f8;
  padding: 2rem 0 6.5rem 0;
  position: relative;
  z-index: 25; }
  @media only screen and (max-width: 768px) {
    #services {
      padding-bottom: 2rem; } }
  #services .wrapper {
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (max-width: 768px) {
      #services .wrapper {
        -ms-flex-direction: column;
        flex-direction: column; } }
  #services .service {
    background: #fff;
    width: 31.33333%;
    float: left;
    margin: -5rem 1% 0 1%;
    padding: 3rem 1.5rem 5rem;
    text-align: center;
    position: relative;
    box-shadow: 0px 0px 57px 0px rgba(4, 6, 9, 0.25);
    border-radius: .35rem; }
    @media only screen and (max-width: 768px) {
      #services .service {
        margin-top: 0;
        width: auto;
        float: none; } }
    @media only screen and (max-width: 768px) {
      #services .service + .service {
        margin-top: 2rem; } }
    #services .service h3 {
      margin: 0 0 0.8rem 0;
      font-size: 1.5rem;
      color: #3f3d4e; }
    #services .service p {
      margin: 0 0 2rem 0;
      color: #3f3d4e;
      line-height: 1.5; }
    #services .service .button {
      position: absolute;
      padding: .5rem 2rem;
      bottom: 2rem;
      left: 3rem;
      right: 3rem; }
    @media only screen and (min-width: 768px) {
      #services .service:after {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: -50px;
        height: 50px;
        border-left: 2px solid #c9c7d3; } }
  #services .middle:after {
    bottom: -105px;
    height: 105px; }
  @media only screen and (min-width: 768px) {
    #services:before {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 54px;
      border-top: 2px solid #c9c7d3;
      width: 59.5%;
      max-width: 959px;
      transform: translateX(-50%); } }

#payment_methods {
  border-top: 1px solid #eee;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  overflow: hidden; }
  #payment_methods .title {
    color: #3f3d4e;
    font-size: 2.5rem;
    margin: 0 0 4rem 0; }

#press_appearances {
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 1px solid #eee;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  overflow: hidden; }
  #press_appearances .title {
    color: #3f3d4e;
    font-size: 2.5rem;
    margin: 0 0 4rem 0; }

#advantages {
  background: #0c4673;
  padding: 5rem 0;
  text-align: center;
  position: relative;
  overflow: hidden; }
  #advantages .title {
    color: #fff;
    font-size: 2.5rem;
    margin: 0 0 4rem 0; }
  @media only screen and (max-width: 768px) {
    #advantages .button {
      width: 90%;
      padding: 0.7rem; } }

#trusted_companies {
  background: #0862a5;
  background: radial-gradient(circle farthest-corner at right bottom, #0971ce 0%, #0862a5 28%, #10283f 79%, #0e1c2c 100%);
  padding: 5rem 0 25rem 0;
  text-align: center;
  position: relative;
  overflow: hidden; }
  #trusted_companies .title {
    color: #fff;
    font-size: 2.5rem;
    margin: 0 0 .75rem 0; }
  #trusted_companies .logos {
    width: 750px;
    max-width: 95%; }
  #trusted_companies #slider_buttons {
    list-style: none;
    padding: 0;
    margin: 2rem 0; }
    #trusted_companies #slider_buttons a {
      text-decoration: none; }
    #trusted_companies #slider_buttons li {
      width: 9px;
      height: 9px;
      margin: 0 .5rem;
      position: relative;
      border-radius: 100%;
      display: inline-block;
      background-color: #eaeaea;
      transition: background .4s ease-in-out; }
      #trusted_companies #slider_buttons li:hover {
        background: #1894dd; }
    #trusted_companies #slider_buttons .current {
      background-color: #1894dd; }
  #trusted_companies #slider {
    list-style: none; }
    #trusted_companies #slider li {
      width: 600px;
      height: 375px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 57px 0px rgba(4, 6, 9, 0.25);
      position: absolute;
      z-index: 3;
      border-radius: .35rem .35rem 0 0; }
      #trusted_companies #slider li img {
        width: 100%; }
      #trusted_companies #slider li:nth-child(1) {
        bottom: -37px;
        left: 0;
        transform: scale(0.8, 0.8);
        z-index: 2; }
      #trusted_companies #slider li:nth-child(2) {
        left: 50%;
        margin-left: -300px;
        z-index: 4;
        bottom: 0; }
      #trusted_companies #slider li:nth-child(3) {
        bottom: -37px;
        right: 0;
        transform: scale(0.8, 0.8);
        z-index: 2; }

.classical_section {
  border-top: 1px solid #eee;
  padding: 3rem 0;
  text-align: center;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .classical_section {
      padding-left: 8px;
      padding-right: 8px; } }
  .classical_section.--with-bg {
    background: url(../../../images/bg_pattern_dark.svg);
    background-repeat: no-repeat;
    background-size: cover; }
  .classical_section.--with-grey-bg {
    background: #fafafa;
    color: #757575; }
  .classical_section.no_bg {
    background: none; }
  .classical_section.text_left {
    text-align: left; }
  .classical_section.text_justify {
    text-align: justify; }
  .classical_section h2 {
    color: #3f3d4e;
    font-size: 3.5rem;
    margin: 0 0 1.2rem 0; }
    .classical_section h2.sm-mb {
      margin-bottom: 0.5em; }
    .classical_section h2.small_font_size {
      font-size: 1.8em; }
  .classical_section h3 {
    color: #3f3d4e;
    font-size: 1.5rem; }
    .classical_section h3 a {
      color: #3f3d4e;
      text-decoration: none; }
      .classical_section h3 a:hover {
        border-bottom: 1px dotted #3f3d4e;
        color: #514f65; }
  .classical_section a.blue {
    color: #0c4673;
    text-decoration: none;
    border-bottom: 1px dotted #0c4673; }
  .classical_section p {
    line-height: 1.5; }
    .classical_section p.light_gray {
      color: #757575; }
  .classical_section ul.light_gray, .classical_section ol.light_gray {
    margin: 0 0 0 1.5em;
    padding: 0; }
    .classical_section ul.light_gray li, .classical_section ol.light_gray li {
      color: #757575; }
  .classical_section ul li, .classical_section ol li {
    margin-bottom: 0.5em;
    line-height: 1.5; }

.both-axis-centered {
  padding-top: 5em;
  min-height: 250px; }

.flex-grid {
  max-width: 900px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 16px auto auto; }

.positions-grid .grid-item {
  width: 260px;
  display: block;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  box-shadow: #e0e0e0 0px 1px 2px;
  background: white;
  padding: 24px;
  transition: all 0.1s ease-in-out;
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0; }
  .positions-grid .grid-item:hover {
    box-shadow: #e0e0e0 0px 2px 40px; }

.flex-grid .grid-item {
  text-align: center;
  text-decoration: none;
  margin: 16px; }

.positions-grid .grid-item .job-img {
  width: auto;
  height: 80px;
  margin: 0px 0px 16px; }

.grid-item img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  outline: 0;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-width: initial;
  border-style: none;
  border-color: initial; }

.positions-grid .grid-item .job-title {
  color: #263238;
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 0px 4px;
  transition: all 0.3s ease-in-out; }

.positions-grid .grid-item .tags {
  font-size: 12px;
  color: #546e7a;
  transition: all 0.3s ease-in-out; }

.my-gallery {
  width: 90%;
  margin: 0 auto; }
  .my-gallery img {
    width: 100%;
    height: auto; }
  .my-gallery figure {
    display: inline-block;
    float: left;
    margin: 0 auto;
    width: 33.33%; }
    @media only screen and (max-width: 768px) {
      .my-gallery figure {
        width: 50%; } }
    @media only screen and (max-width: 480px) {
      .my-gallery figure {
        width: 100%; } }
  .my-gallery figcaption {
    display: none; }

.pswp__caption__center {
  text-align: center !important; }

.button {
  border: none;
  outline: none;
  border-radius: 2rem;
  padding: .6rem 3.5rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  text-align: center;
  max-width: 100%;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  display: inline-block; }
  @media only screen and (max-width: 768px) {
    .button + .button {
      margin-top: 10px; } }
  @media only screen and (min-width: 768px) {
    .button + .button {
      margin-left: 2rem; } }

.button_highlight {
  background: #1894dd; }
  .button_highlight:hover {
    background: #1685c6;
    color: #f2f2f2; }

.button_secondary {
  background: rgba(250, 250, 250, 0.35);
  color: #f2f2f2; }
  .button_secondary:hover {
    background: rgba(230, 230, 230, 0.35);
    color: #f2f2f2; }

.button_cta {
  background: #9db838; }
  .button_cta:hover {
    background: #82992e;
    color: #f2f2f2; }

.button_small {
  padding: .3rem 1.5rem; }

.button_large {
  padding: 1rem 5rem;
  font-size: 1.35rem; }

.button_callout {
  background: #1894dd;
  padding: 15px 45px;
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  display: inline;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  -webkit-appearance: button;
  cursor: pointer; }
  .button_callout:hover {
    background: #2ca2e8; }

.banner {
  width: 100%;
  background-color: #0862a5;
  background-image: radial-gradient(circle farthest-corner at right bottom, #0971ce 0%, #0862a5 28%, #10283f 79%, #0e1c2c 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 15; }
  .banner .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10; }
  @media only screen and (max-width: 768px) {
    .banner {
      background-image: radial-gradient(circle farthest-corner at right bottom, #0e1c2c 0%, #0971ce 28%, #10283f 79%, #0862a5 100%); } }
  @media only screen and (min-width: 768px) {
    .banner.home {
      background-color: #0c112e;
      background-image: url("../../../images/banners/banner_home.png"); }
    .banner.banner_security {
      background-image: url("../../../images/banners/banner_security.gif");
      background-position: center center; }
      .banner.banner_security .overlay {
        background-color: rgba(0, 0, 0, 0.5); }
    .banner.banner_careers {
      background-image: url("../../../images/banners/banner_careers.jpg");
      background-position: center center; }
      .banner.banner_careers .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_backend_engineer {
      background-image: url("../../../images/banners/banner_backend_engineer.png");
      background-position: center center; }
      .banner.banner_backend_engineer .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_digital_marketer {
      background-image: url("../../../images/banners/banner_digital_marketer.png");
      background-position: center center; }
      .banner.banner_digital_marketer .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_frontend_engineer {
      background-image: url("../../../images/banners/banner_frontend_engineer.png");
      background-position: center center; }
      .banner.banner_frontend_engineer .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_ui_ux_engineer {
      background-image: url("../../../images/banners/banner_ui_ux_engineer.png");
      background-position: center center; }
      .banner.banner_ui_ux_engineer .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_community_manager {
      background-image: url("../../../images/banners/banner_community_manager.png");
      background-position: center center; }
      .banner.banner_community_manager .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_android_developer {
      background-image: url("../../../images/banners/banner_android_developer.png");
      background-position: center center; }
      .banner.banner_android_developer .overlay {
        background-color: rgba(0, 0, 0, 0.4); }
    .banner.banner_apis {
      background-image: url("../../../images/banners/banner_apis.jpg"); }
    .banner.banner_transfer {
      background-image: url("../../../images/banners/banner_transfer.jpg"); }
      .banner.banner_transfer .overlay {
        background-color: rgba(0, 0, 0, 0.5); }
    .banner.banner_cnp {
      background-image: url("../../../images/banners/banner_cnp.png"); }
    .banner.banner_dmp {
      background: url("../../../images/banners/banner_dmp.jpg"); }
    .banner.banner_pal {
      background-image: url("../../../images/banners/banner_pal.jpg"); } }
  .banner .content {
    padding: 4rem 0 2.5rem;
    text-align: center;
    position: relative;
    z-index: 15; }
    @media only screen and (min-width: 768px) {
      .banner .content {
        padding-bottom: 4.5rem; } }
    .banner .content .title, .banner .content .sub_title {
      color: #fff;
      text-align: center; }
    .banner .content .title {
      font-family: Graphik, Helvetica, sans-serif;
      font-size: 3.5rem;
      margin: 0;
      word-wrap: break-word; }
    .banner .content .sub_title {
      font-weight: 400;
      font-size: 1.25rem;
      max-width: 700px;
      margin: 0 auto 2rem auto;
      line-height: 1.75; }
      @media only screen and (max-width: 768px) {
        .banner .content .sub_title {
          padding-left: 5px;
          padding-right: 5px; }
          .banner .content .sub_title span {
            display: block; } }
    .banner .content .play_button {
      text-align: center;
      width: 75px;
      display: block;
      margin: 0.8rem auto;
      cursor: pointer;
      transition: all .3s ease-in-out; }
      .banner .content .play_button:hover {
        transform: scale(1.1); }
        .banner .content .play_button:hover path {
          fill: #1894dd; }
    .banner .content .icon {
      text-align: center;
      width: 75px;
      height: 75px;
      display: block;
      margin: 0.8rem auto;
      cursor: pointer;
      transition: all .3s ease-in-out; }
      .banner .content .icon a {
        color: #fff;
        font-size: 4rem; }
        .banner .content .icon a i {
          color: rgba(250, 250, 250, 0.35); }
      .banner .content .icon:hover {
        transform: scale(1.1); }
    .banner .content .button_wrapper {
      text-align: center;
      margin: 3rem 0; }
      .banner .content .button_wrapper .button {
        margin-left: .75rem;
        margin-right: .75rem; }
        @media only screen and (max-width: 768px) {
          .banner .content .button_wrapper .button {
            width: 90%;
            margin-left: 0;
            margin-right: 0; } }
  .banner .content.sm-pb {
    padding-bottom: 5em; }
    .banner .content.sm-pb .see_more {
      bottom: 20px; }

.banner_white_player .play_button path {
  fill: #fff; }

.banner_white_player .play_button:hover {
  transform: scale(1.1); }
  .banner_white_player .play_button:hover path {
    fill: #eaeaea !important; }

#cookies-warning {
  font-family: Graphik, Helvetica, Arial, sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #2f2f2f;
  text-align: left;
  line-height: 1.5;
  color: #E2E2E2;
  font-size: 10px;
  width: 300px;
  display: none; }
  #cookies-warning a {
    color: #E2E2E2;
    text-decoration: none; }

#cookies-warning-text {
  padding: 10px; }

#cookies-warning-close {
  float: right;
  display: block;
  padding: 5px 7px;
  margin-left: 5px;
  color: #E2E2E2;
  font-size: 12px; }

#cookies-warning-close:hover, #cookies-warning-close:active, #cookies-warning-close:focus {
  color: #E2E2E2;
  background: #525252;
  text-decoration: none; }

.cookie, .top_absolute {
  font-family: Graphik, Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  position: fixed;
  z-index: 9998;
  padding: 15px 20px;
  color: #000;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .cookie .cookie_button, .top_absolute .cookie_button {
    display: inline;
    margin-left: 15px;
    padding: 4px 10px;
    background-color: #55acee;
    border-radius: 3px;
    color: #fff;
    cursor: pointer; }
    .cookie .cookie_button:hover, .top_absolute .cookie_button:hover {
      background-color: #1894dd; }
  .cookie a, .top_absolute a {
    color: #337ab7;
    text-decoration: underline; }
    .cookie a:hover, .top_absolute a:hover {
      color: #1894dd; }

.cookie {
  left: 0;
  right: 0;
  bottom: 0; }

.box {
  color: #fff;
  padding: 11px 15px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px; }
  .box.box--info {
    background-color: #00baf3; }
  .box.box--success {
    background-color: #99c93d;
    color: #fff; }
  .box.box--warning {
    background-color: #fcf8e3;
    color: #8a6d3b;
    border-color: #faebcc; }
  .box.box--error {
    background: #d14130;
    color: #fff; }
  .box.box--muted {
    background-color: #eee;
    color: #3a3a3a;
    border: 1px solid #e2e2e2; }
  .box.box--muted-dark {
    background-color: #133259;
    color: #e2e1e7; }
  .box.box--primary {
    background-color: #573e81; }
  .box.--primary-dark {
    background: linear-gradient(to right, #133259 30%, #0d233e); }

.info_box {
  background-color: #1375af;
  color: #fff;
  line-height: 1.5;
  border-radius: 4px;
  padding: 1rem; }
  .info_box.info_box--muted {
    background-color: #eee;
    color: #3a3a3a;
    border: 1px solid #e2e2e2; }
  @media only screen and (max-width: 768px) {
    .info_box {
      width: 95%;
      margin: auto; } }

.info_box_delight {
  background-color: #f2f2f2;
  border: 1px solid #d9dee9;
  color: #000;
  line-height: 1.5;
  border-radius: .3em;
  padding: 1em;
  margin-bottom: 1em;
  text-align: center; }
  @media only screen and (max-width: 768px) {
    .info_box_delight {
      width: 95%;
      margin: auto; } }

.b-services {
  position: relative;
  text-align: center;
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .b-services__item:nth-of-type(2n) {
      background: #f5f7f9; }
      .b-services__item:nth-of-type(2n) .b-services__item__icon {
        color: #1894dd; } }
  @media only screen and (min-width: 768px) {
    .b-services:nth-of-type(2n+1) {
      background: linear-gradient(90deg, #fff 50%, #f5f7f9 50%); }
      .b-services:nth-of-type(2n+1) .b-services__item:nth-of-type(2n) .b-services__item__icon {
        color: #1894dd; }
    .b-services:nth-of-type(2n) {
      background: linear-gradient(90deg, #f5f7f9 50%, #fff 50%); }
      .b-services:nth-of-type(2n) .b-services__item:nth-of-type(2n+1) .b-services__item__icon {
        color: #1894dd; } }
  .b-services__title {
    color: #0c4673;
    font-size: 2.5rem;
    margin: 0 0 4rem 0; }
  .b-services__item {
    min-height: 300px;
    padding: 2rem 0; }
    .b-services__item__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px; }
    .b-services__item__inner {
      padding: 0 2rem; }
    .b-services__item__icon {
      font-size: 30px;
      color: #ef8b26;
      margin-top: 20px;
      border: 2px solid;
      border-radius: 80px;
      height: 70px;
      width: 70px;
      display: block;
      margin: auto;
      text-align: center;
      line-height: 65px; }
    .b-services__item__list {
      text-align: left;
      color: #6e7b82; }
    .b-services__item__list-item {
      margin-bottom: 1rem;
      font-size: 120%;
      text-align: justify;
      line-height: 28px; }
    .b-services__item p {
      text-align: justify;
      font-size: 120%;
      line-height: 28px;
      color: #6e7b82; }
    .b-services__item p + p {
      margin-top: 20px; }
    .b-services__item a {
      display: inline-block;
      margin-top: 5px;
      font-weight: bold;
      color: #1894dd; }
    @media only screen and (max-width: 768px) {
      .b-services__item .button {
        width: 80%; } }

.b-account-types {
  position: relative;
  text-align: center;
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .b-account-types__item:nth-of-type(2n) {
      background: #f5f7f9; }
      .b-account-types__item:nth-of-type(2n) .b-account-types__item__icon {
        color: #1894dd; } }
  @media only screen and (min-width: 768px) {
    .b-account-types:nth-of-type(2n+1) {
      background: linear-gradient(90deg, #fff 50%, #f5f7f9 50%); }
      .b-account-types:nth-of-type(2n+1) .b-account-types__item:nth-of-type(2n) .b-account-types__item__icon {
        color: #1894dd; }
    .b-account-types:nth-of-type(2n) {
      background: linear-gradient(90deg, #f5f7f9 50%, #fff 50%); }
      .b-account-types:nth-of-type(2n) .b-account-types__item:nth-of-type(2n+1) .b-account-types__item__icon {
        color: #1894dd; } }
  .b-account-types__title {
    color: #0c4673;
    font-size: 2.5rem;
    margin: 0 0 4rem 0; }
  .b-account-types__item {
    min-height: 300px;
    padding: 2rem 0; }
    .b-account-types__item__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px; }
    .b-account-types__item__inner {
      padding: 0 2rem; }
    .b-account-types__item__icon {
      font-size: 30px;
      color: #ef8b26;
      margin-top: 20px;
      border: 2px solid;
      border-radius: 80px;
      height: 70px;
      width: 70px;
      display: block;
      margin: auto;
      text-align: center;
      line-height: 65px; }
    .b-account-types__item__list {
      text-align: left;
      color: #6e7b82; }
    .b-account-types__item__list-item {
      margin-bottom: 1rem;
      font-size: 120%;
      text-align: justify;
      line-height: 28px; }
    .b-account-types__item p {
      text-align: justify;
      font-size: 120%;
      line-height: 28px;
      color: #6e7b82; }
    .b-account-types__item p + p {
      margin-top: 20px; }
    .b-account-types__item a {
      display: inline-block;
      margin-top: 5px;
      font-weight: bold;
      color: #1894dd; }
    @media only screen and (max-width: 768px) {
      .b-account-types__item .button {
        width: 80%; } }
  @media only screen and (max-width: 768px) {
    .b-account-types__item p {
      text-align: left; } }
  .b-account-types .button {
    margin-bottom: 20px; }
    @media only screen and (max-width: 768px) {
      .b-account-types .button {
        width: 90%;
        padding: 0.7rem;
        margin-top: 20px; } }

.service_description {
  background: #0862a5;
  border-top: 4px solid rgba(0, 0, 0, 0.4);
  background: radial-gradient(circle farthest-corner at right bottom, #0971ce 0%, #0862a5 28%, #10283f 79%, #0e1c2c 100%);
  padding: 3rem 0 2em 0;
  text-align: justify;
  position: relative;
  overflow: hidden;
  color: #fff; }
  @media only screen and (max-width: 768px) {
    .service_description {
      text-align: left; } }
  .service_description .title {
    text-align: center;
    font-size: 2.5rem;
    color: #fff;
    margin: 0;
    margin-bottom: 1.5rem; }
  .service_description ul, .service_description ol {
    margin: 0 auto 1.8rem;
    max-width: 800px; }
    .service_description ul li, .service_description ol li {
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 0.6rem; }
  .service_description p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    max-width: 900px;
    display: block;
    margin: .5em auto 1.5em auto; }
    @media only screen and (max-width: 768px) {
      .service_description p {
        padding-left: 8px;
        padding-right: 8px; } }
    .service_description p a {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px dotted #fff; }

.accordion {
  list-style: none;
  padding: 0;
  margin: 0; }
  @media only screen and (max-width: 768px) {
    .accordion {
      padding-left: 5px;
      padding-right: 5px; } }
  .accordion .accordion__item {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    margin-bottom: 1em;
    box-shadow: 0 2px 40px rgba(24, 148, 221, 0.2);
    transition: all 0.3s ease-in-out; }
    .accordion .accordion__item.active {
      border: none; }
    .accordion .accordion__item--blue {
      background: #1894dd; }
      .accordion .accordion__item--blue:hover {
        background: #1688cb; }
    .accordion .accordion__item--orange {
      background: #fe6600; }
      .accordion .accordion__item--orange:hover {
        background: #ea5e00; }
    .accordion .accordion__item--violet {
      background: #79288b;
      margin-bottom: 0; }
      .accordion .accordion__item--violet:hover {
        background: #6b237b; }
    .accordion .accordion__item--green {
      background: #449d44; }
      .accordion .accordion__item--green:hover {
        background: #3e8f3e; }
    .accordion .accordion__item--red {
      background: #d20f05; }
      .accordion .accordion__item--red:hover {
        background: #be0e05; }
    .accordion .accordion__item.active .accordion__item__title:before {
      transform: rotate(45deg); }
    .accordion .accordion__item .accordion__item__title {
      cursor: pointer;
      padding: 0;
      margin: 0; }
      .accordion .accordion__item .accordion__item__title:before {
        color: #fff;
        margin-left: 1em;
        content: "\f067";
        position: relative;
        display: inline-block;
        bottom: 0.1em;
        margin-right: 10px;
        font-size: 74%;
        -webkit-font-smoothing: antialiased;
        text-decoration: inherit;
        text-rendering: auto;
        transition: all 0.3s ease;
        font-family: "FontAwesome" !important;
        font-style: normal !important;
        font-weight: normal !important; }
      .accordion .accordion__item .accordion__item__title a {
        text-decoration: none;
        color: #fff;
        display: inline-block;
        padding: 1em 0 1em 0; }
        @media only screen and (max-width: 768px) {
          .accordion .accordion__item .accordion__item__title a {
            padding: 0.2em 0 0.2em 0; } }
    .accordion .accordion__item .accordion__item__content {
      display: none;
      color: #6e7b82; }
      .accordion .accordion__item .accordion__item__content p {
        font-size: 1rem;
        line-height: 1.8rem;
        width: 900px;
        max-width: 100%;
        display: block;
        margin: .5em auto 1.5em auto; }
      .accordion .accordion__item .accordion__item__content a {
        text-decoration: none;
        color: #1894dd; }

@media only screen and (max-width: 768px) {
  .question {
    padding-left: 5px;
    padding-right: 5px; } }

.question .question__item {
  background-color: #fff;
  border: 2px solid #e5e5e5;
  padding: 0.5em 1em;
  margin-bottom: 1em; }
  .question .question__item--pink.active {
    background-color: #fef9f5; }
  .question .question__item.active:hover {
    background-color: #fff; }
  .question .question__item.active .question__item_title:before {
    transform: rotate(45deg); }
  .question .question__item:hover {
    background-color: #fafafa; }
  .question .question__item:focus, .question .question__item:active {
    background-color: #fff; }
  .question .question__item .question__item_title {
    font-size: 1em;
    cursor: pointer;
    margin: 0;
    padding: 0.8em 0 0.8em 0;
    color: #3f3d4e; }
    .question .question__item .question__item_title:before {
      content: "\f067";
      position: relative;
      display: inline-block;
      bottom: 0.1em;
      margin-right: 10px;
      font-size: 74%;
      -webkit-font-smoothing: antialiased;
      text-decoration: inherit;
      text-rendering: auto;
      transition: all 0.3s ease;
      font-family: "FontAwesome" !important;
      font-style: normal !important;
      font-weight: normal !important; }
    .question .question__item .question__item_title:focus, .question .question__item .question__item_title:active {
      background-color: #fff; }
    .question .question__item .question__item_title a {
      text-decoration: none;
      display: inline-block; }
      .question .question__item .question__item_title a:focus, .question .question__item .question__item_title a:active {
        background-color: #fff; }
  .question .question__item .question__item_answer {
    display: none;
    color: #6e7b82; }
    .question .question__item .question__item_answer ul, .question .question__item .question__item_answer ol {
      margin-bottom: 1.8rem; }
      .question .question__item .question__item_answer ul li, .question .question__item .question__item_answer ol li {
        font-size: 1rem;
        line-height: 1.8rem;
        margin-bottom: 0.6rem; }
    .question .question__item .question__item_answer p {
      font-size: 1rem;
      line-height: 1.8rem;
      width: 900px;
      max-width: 100%;
      display: block;
      margin: .5em auto 1.5em auto; }
    .question .question__item .question__item_answer a {
      text-decoration: none;
      color: #1894dd; }
      .question .question__item .question__item_answer a:hover {
        text-decoration: underline; }

.modal-mask {
  font-family: Graphik, Helvetica, sans-serif;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: -ms-flexbox;
  display: flex;
  box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.modal-container {
  margin: 0 auto;
  max-height: 90%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all .3s ease;
  color: #3a3a3a; }

.modal--small {
  width: 400px; }

.modal-header {
  background: #1375af;
  color: #fff;
  text-align: center; }
  .modal-header img {
    width: 200px;
    max-width: 100%; }

.modal-container > * {
  padding: 30px; }

.modal-header h3 {
  margin: 0;
  color: #fff;
  font-size: 21px;
  line-height: 1.3em;
  font-weight: 600; }

.modal-body + .modal-footer {
  margin-top: -30px; }

.modal-footer {
  padding-top: 0;
  margin-bottom: 20px;
  font-size: 16px; }
  @media only screen and (max-width: 768px) {
    .modal-footer {
      padding: 6px 6px 30px 6px; } }
  .modal-footer a {
    color: #3a3a3a !important;
    margin-left: 0 !important; }
    .modal-footer a:hover {
      text-decoration: underline; }

.modal-body form {
  line-height: 1; }

.form-group {
  margin-bottom: 25px; }

.form-group input[type=email], .form-group input[type=password], .form-group input[type=text], .form-group textarea, .input-group input[type=email], .input-group input[type=password], .input-group input[type=text], .input-group textarea {
  height: 45px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  font-size: 15px;
  width: 100%;
  outline: 0;
  line-height: normal;
  -webkit-appearance: textfield; }

.modal-container .error {
  font-size: inherit;
  padding-bottom: 20px; }

html, body {
  height: 100%; }

body {
  overflow-x: hidden; }

#signup, .form_envelope, .full_form_page {
  background: #f1f0f2 url(../../../images/bg_pattern_dark.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0 8rem 0;
  text-align: center;
  position: relative; }
  #signup--no-padding, .form_envelope--no-padding, .full_form_page--no-padding {
    padding: 0; }
  @media only screen and (max-width: 768px) {
    #signup, .form_envelope, .full_form_page {
      padding-bottom: 5em; } }
  #signup .title, .form_envelope .title, .full_form_page .title {
    font-size: 2.5rem;
    color: #3f3d4e;
    margin: 0; }
    #signup .title img, .form_envelope .title img, .full_form_page .title img {
      width: 200px;
      max-width: 100%; }
  #signup .sub_title, .form_envelope .sub_title, .full_form_page .sub_title {
    font-size: 1.2rem;
    color: #b9b7c6;
    max-width: 600px;
    display: block;
    margin: .5em auto 3em auto; }
    @media only screen and (max-width: 768px) {
      #signup .sub_title, .form_envelope .sub_title, .full_form_page .sub_title {
        padding-left: 4px;
        padding-right: 4px; } }
  #signup .form_wrapper, .form_envelope .form_wrapper, .full_form_page .form_wrapper {
    background: #fff;
    box-shadow: 0px 0px 62px 0px rgba(4, 6, 9, 0.13);
    padding: 0;
    border-radius: .35em;
    max-width: 600px;
    margin: 0 auto; }
    @media only screen and (max-width: 768px) {
      #signup .form_wrapper, .form_envelope .form_wrapper, .full_form_page .form_wrapper {
        max-width: 95%; } }
  #signup .form_container, .form_envelope .form_container, .full_form_page .form_container {
    float: left;
    width: 100%;
    padding: 2.5em 2em;
    border-radius: .35em; }
    #signup .form_container .input_wrapper, .form_envelope .form_container .input_wrapper, .full_form_page .form_container .input_wrapper {
      width: 100%;
      display: block;
      margin-bottom: 2em;
      float: left;
      text-align: left; }
      #signup .form_container .input_wrapper.has-error input, #signup .form_container .input_wrapper.has-error textarea, #signup .form_container .input_wrapper.has-error select, .form_envelope .form_container .input_wrapper.has-error input, .form_envelope .form_container .input_wrapper.has-error textarea, .form_envelope .form_container .input_wrapper.has-error select, .full_form_page .form_container .input_wrapper.has-error input, .full_form_page .form_container .input_wrapper.has-error textarea, .full_form_page .form_container .input_wrapper.has-error select {
        border: 1px solid #d14130; }
    #signup .form_container input[type="text"],
    #signup .form_container input[type="email"],
    #signup .form_container input[type="tel"],
    #signup .form_container input[type="password"],
    #signup .form_container input[type="submit"] textarea, .form_envelope .form_container input[type="text"],
    .form_envelope .form_container input[type="email"],
    .form_envelope .form_container input[type="tel"],
    .form_envelope .form_container input[type="password"],
    .form_envelope .form_container input[type="submit"] textarea, .full_form_page .form_container input[type="text"],
    .full_form_page .form_container input[type="email"],
    .full_form_page .form_container input[type="tel"],
    .full_form_page .form_container input[type="password"],
    .full_form_page .form_container input[type="submit"] textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    #signup .form_container input[type="text"],
    #signup .form_container input[type="email"],
    #signup .form_container input[type="tel"],
    #signup .form_container input[type="password"],
    #signup .form_container select,
    #signup .form_container textarea,
    #signup .form_container input[type="submit"], .form_envelope .form_container input[type="text"],
    .form_envelope .form_container input[type="email"],
    .form_envelope .form_container input[type="tel"],
    .form_envelope .form_container input[type="password"],
    .form_envelope .form_container select,
    .form_envelope .form_container textarea,
    .form_envelope .form_container input[type="submit"], .full_form_page .form_container input[type="text"],
    .full_form_page .form_container input[type="email"],
    .full_form_page .form_container input[type="tel"],
    .full_form_page .form_container input[type="password"],
    .full_form_page .form_container select,
    .full_form_page .form_container textarea,
    .full_form_page .form_container input[type="submit"] {
      border-radius: .35em;
      padding: 1em 1.5em;
      font-size: .9em;
      color: #77778f;
      width: 100%;
      display: inline;
      border: 1px solid #dedce0;
      outline: none;
      margin: .25em 0 0 0; }
    #signup .form_container input[type="tel"].input--more-padding-left, .form_envelope .form_container input[type="tel"].input--more-padding-left, .full_form_page .form_container input[type="tel"].input--more-padding-left {
      padding-left: 3.5em; }
    #signup .form_container select, .form_envelope .form_container select, .full_form_page .form_container select {
      background-color: #fff; }
  @media only screen and (max-width: 768px) {
    #signup .button, .form_envelope .button, .full_form_page .button {
      width: 90%;
      padding: 0.7rem; } }

.form_envelope {
  padding: 2rem 0;
  background: none; }
  .form_envelope .form_container {
    padding: 0;
    border-radius: 0; }
  .form_envelope .form_wrapper {
    background: none;
    box-shadow: none; }
    .form_envelope .form_wrapper label {
      color: #757575; }

.full_form_page {
  font-family: Graphik, sans-serif;
  background: #0862a5;
  background-image: radial-gradient(circle farthest-corner at right bottom, #0971ce 0%, #0862a5 28%, #10283f 79%, #0e1c2c 100%);
  height: 100%;
  background-attachment: fixed; }
  .full_form_page .title {
    margin-bottom: 1.5rem; }
    .full_form_page .title a {
      color: #fff;
      text-decoration: none; }
      .full_form_page .title a:hover {
        color: #f2f1f8; }
  .full_form_page .sub_title {
    font-size: 1.2rem;
    margin: .5em auto 1.5em auto; }
  .full_form_page .form_wrapper {
    width: 450px; }
    .full_form_page .form_wrapper a {
      text-decoration: none;
      color: #777; }
      .full_form_page .form_wrapper a:hover {
        text-decoration: underline; }
    .full_form_page .form_wrapper .button {
      width: 100%;
      max-width: 100%;
      border-radius: 4px; }
  .full_form_page footer {
    margin-top: 2rem;
    color: #666; }

@media only screen and (max-width: 1050px) {
  .banner .content .play_button {
    width: 60px; } }

@media only screen and (max-width: 800px) {
  .banner .content .play_button {
    width: 50px; } }

/*# sourceMappingURL=app.css.map */
